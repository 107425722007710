<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">Guest Record Setting</div>
  </div>
  <div
    class="layout-box-content-section mb-3"
    style="height: calc(-157px + 100vh)"
  >
    <div class="row pb-5">
      <div class="col-lg-4 col-md-4 col-12">
        <div class="custom-form-group">
          <label class="form-label"
            >Days<span class="text-danger">*</span></label
          >
          <input
            type="text"
            :disabled="showloader"
            v-model="days"
            class="form-control text-capitalize"
            placeholder="Enter Days"
            autocomplete="off"
          />
          <div class="custom-error" v-if="v$.days.$error">
            {{ v$.days.$errors[0].$message }}
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-5">
      <div class="col-lg-12 col-md-12 col-12 text-center">
        <button
          type="button"
          class="btn modal-bulk-next-btn"
          @click="submitDays()"
          :disabled="btnloader"
        >
          <span v-if="!btnloader">Submit</span>
          <div
            class="spinner-border text-light custom-spinner-loader-btn"
            role="status"
            v-if="btnloader"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>
    
  <script>
import { required, helpers } from "@vuelidate/validators";
import ApiService from "../../../service/ApiService";
import useValidate from "@vuelidate/core";
export default {
  data() {
    return {
      v$: useValidate(),
      btnloader: false,
      days: "",
      client_info: null,
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  validations() {
    return {
      days: {
        required: helpers.withMessage("Please enter days", required),
      },
    };
  },

  mounted() {
    this.client_info = JSON.parse(localStorage.client_info);
    this.days = this.client_info.maa49;
  },
  methods: {
    submitDays() {
  this.v$.days.$validate(); 
  if (!this.v$.days.$error) {
    var formData = new FormData();
    formData.append("maa49", this.days);
    this.client_info.maa49 = this.days;
    localStorage.setItem('client_info', JSON.stringify(this.client_info));
    this.ApiService.updateGuestValidationSetting(formData).then((data) => {
      if (data.status == 200) {
        var successMsg = data.message;
        this.$toast.open({
          message: successMsg,
          type: "success",
          duration: 3000,
          position: "top-right",
        });
      } else {
        var errorMsg = data.message;
        this.$toast.open({
          message: errorMsg,
          type: "error",
          duration: 3000,
          position: "top-right",
        });
      }
    });
  } else {
    this.$error;
  }
},

  },
};
</script>
    
  <style></style>